import React from "react";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "../StateProvider";
import { getBasketTotal } from "../reducer";
import { Button } from "@material-ui/core/";

function Subtotal({ onClick }) {
  const [{ basket }] = useStateValue();
  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(value) => (
          <>
            <p style={{ fontSize: "20px" }}>
              <strong>
                {" "}
                Subtotal ({basket.length} items): &#x20b9; {value}
              </strong>
            </p>
          </>
        )}
        decimalScale={2}
        value={getBasketTotal(basket)}
        displayType={"text"}
        thousandSeparator={true}
      />

      <Button
        onClick={onClick}
        style={{ padding: "8px 14px", marginTop: "20px" }}
        variant="contained"
        color="primary"
      >
        Proceed to Checkout
      </Button>
    </div>
  );
}

export default Subtotal;
