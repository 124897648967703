import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./component/Home";
import Header from "./component/Header";
import Footer from "./component/Footer";
import ProductInfo from "./component/ProductInfo";
import Cart from "./component/Cart";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route
            exact
            path="/:subject/:board/:course/:id"
            render={(props) => (
              <>
                <Header />

                <ProductInfo {...props} key={window.location.pathname} />

                <Footer />
              </>
            )}
          />
          <Route exact path="/">
            <Home />

            <Footer />
          </Route>


          <Route
            exact
            path="/cart"
            render={(props) => (
              <>
                <Header />

                <Cart {...props} />

                <Footer />
              </>
            )}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
