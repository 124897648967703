import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import HttpsRedirect from 'react-https-redirect';
import {StateProvider} from './StateProvider';
import reducer, {initialState} from './reducer';

ReactDOM.render(
<StateProvider initialState={initialState} reducer={reducer}>
  <HttpsRedirect>
    <App />
    </HttpsRedirect>
    </StateProvider>
 ,
  document.getElementById('root')
);

