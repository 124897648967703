import React, { useState, useEffect } from "react";
import "./Home.css";
import { Container, Grid } from "@material-ui/core/";
import { Product } from "./Product";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 158,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    padding: 0,
  },
  hideIconPadding: {
    "& .MuiSelect-outlined": {
      padding: "14px 10px",
    },
  },
}));

function Home(props) {
  const classes = useStyles();

  const [filter, setfilter] = useState("");
  const [data, setdata] = useState([]);
  const [search, setSearch] = useState("");
  const [filterdata, setFilterData] = useState([]);

  const handleChange1 = (event) => {
    setfilter(event.target.value);
  };

  useEffect(() => {
    const abortController = new AbortController();
    // const signal = abortController.signal;
    const fetch = async () => {
      const res = await axios.post(
        "https://api.gharpeshiksha.com/OnlineCoursePricingDetails/getCourseDetail"
      );
      setdata(res.data.OnlineCoursePriceDetails);
      setFilterData(res.data.OnlineCoursePriceDetails);
    };
    fetch();

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    setFilterData(
      data?.filter((doc) =>
        doc.Title.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, data]);

  useEffect(() => {
    if (filter === "" || filter === "all") setFilterData(data);
    else {
      setFilterData(
        data?.filter((doc) => {
          let course = doc.Course.split(" ");
          return course[1] === filter;
        })
      );
    }
    return () => filter;
  }, [filter, data]);

  return (
    <>
      <Header setSearch={setSearch} />
      <div className="section_top"></div>
      <section className="section">
        <div className="filters">
          <Container
            maxWidth="lg"
            style={{ alignItems: "center", display: "flex" }}
          >
            <div className="fil_home">
              <span>Filter By</span>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  All Products
                </InputLabel>
                <Select
                  className={classes.hideIconPadding}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={filter}
                  onChange={handleChange1}
                  label="All Products"
                >
                  <MenuItem value="all">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value="IX">Class IX</MenuItem>
                  <MenuItem value="X">Class X</MenuItem>
                  <MenuItem value="XI">Class XI</MenuItem>
                  <MenuItem value="XII">Class XII</MenuItem>
                </Select>
              </FormControl>
            </div>
            <span style={{ marginLeft: "auto" }}>{filterdata.length} Product{filterdata.length > 1 ? "s" : ""}</span>
          </Container>
        </div>

        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {filterdata.length
              ? filterdata.map(
                  ({
                    CourseId,
                    DiscountedPrice,
                    Course,
                    Board,
                    Description,
                    OriginalPrice,
                    Subject,
                    Title,
                    Image,
                  }) => (
                    <Product
                      key={CourseId}
                      id={CourseId}
                      title={Title}
                      image={Image}
                      price={OriginalPrice}
                      discount={DiscountedPrice}
                      desc={Description}
                      subject={Subject}
                      course={Course}
                      board={Board}
                    />
                  )
                )
              : "No Courses Available at the moment"}
          </Grid>
        </Container>
      </section>
    </>
  );
}

export default Home;
