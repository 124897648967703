import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography, InputBase } from "@material-ui/core/";
import { fade, makeStyles } from "@material-ui/core/styles";
import "./Header.css";
import logo from "../images/logo.png";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { useStateValue } from "../StateProvider";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 128,
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      minHeight: 56,
    },
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "26%",
    background: "#a2a2a2!important",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Header({ setSearch }) {
  const classes = useStyles();
  const [{ basket }] = useStateValue();

  const history = useHistory();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: "white" }}>
        <Toolbar className={classes.toolbar}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="head_nav"
                style={{ maxWidth: "75px" }}
                src={logo}
                alt="logo"
              />
              <Typography className="title" variant="h5" noWrap>
                GharPeShiksha
              </Typography>
            </div>
          </Link>
          <div className="nav_link_top">
            <div className="top_nav">
              <Link className="footer_link_a" to="/">
                {" "}
                <span className="nav-link">Class 9-10 - CBSE/ICSE/State</span>
              </Link>
            </div>

            <div className="top_nav">
              <Link className="footer_link_a" to="/">
                {" "}
                <span className="nav-link">Class 10-11 - CBSE/ICSE/State</span>
              </Link>
            </div>
          </div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={e => setSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div>
            <IconButton
              onClick={() => history.push("/cart")}
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={basket.length} color="secondary">
                <ShoppingBasketOutlinedIcon className="basket" />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
