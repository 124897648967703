import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "./Form.css";
import logo from "../images/logo.png";

const styles = (theme) => ({
  root: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img style={{ maxWidth: "55px" }} src={logo} alt="logo" />{" "}
      <Typography className="fff">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export default function CustomizedDialogs({ hopen, close, open }) {


  return (
    <div>
      <Dialog
        PaperProps={{ style: { margin: "0", width: " calc(100% - 15px)" } }}
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={close}>
          GharPeShiksha
        </DialogTitle>
        <DialogContent dividers>
          <form
            className="hero-form"
            autoComplete="off"
            action="https://www.gharpeshiksha.com/PayuRedirectVC.jsp"
            method="post"
            // onSubmit={handleSubmit}
          >
            <div>
              <h3 className="hero-form-desc">Enter Your Details</h3>
              <div className="form-group">
                <div className="pr">
                  <input
                    maxLength="20"
                    minLength="3"
                    type="text"
                    className="form-control learn-from-home-input"
                    name="firstname"
                    placeholder="Name"
                    required
                    // value={name}
                    // onChange={e => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="pr">
                  <input
                    type="Email"
                    className="form-control learn-from-home-input"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    placeholder="email"
                    name="email"
                    required
                    // value={email}
                    // onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="pr">
                  <input
                    type="number"
                    className="form-control learn-from-home-input"
                    pattern="[789][0-9]{9}"
                    placeholder="Mobile Number"
                    name="phone"
                    maxLength="10"
                    required
                    // value={phone}
                    // onChange={e => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="pr">
                  <input
                    type="hidden"
                    className="form-control learn-from-home-input"
                    name="productinfo"
                    value="Home Tutor"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="pr">
                  <input
                    type="number"
                    className="form-control learn-from-home-input"
                    placeholder="amount"
                    name="amount"
                    required
                  />
                </div>
              </div>
              <div className="formDe">
                <input
                  type="submit"
                  autoFocus
                  variant="contained"
                  color="primary"
                  placeholder="Submit"
                  value="Submit"
                />
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
