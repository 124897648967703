export const initialState = {
    basket: [],
    addedItems:[],
    total: 0,
    user: null
  };
  
  // Selector
  export const getBasketTotal = (basket) => 
    basket?.reduce((amount, item) => item.price + amount, 0);
  
  const reducer = (state, action) => {
    // console.log(action);
 
    switch (action.type) {
      case "ADD_TO_BASKET":
        let addedItem = action.item
        let existed_item= state.basket.find(item=> action.item.id === item.id)
      //  console.log(existed_item)
      
       if(existed_item)
       {
        existed_item.dis=action.item.dis
        existed_item.price=action.item.price
        existed_item.sub=action.item.sub
        // action.item.price= existed_item.price
      // let to=   existed_item.price += addedItem.price
 
      return {
          ...state,
          // total: action.item.price
        }
      }else{
        // existed_item.price=0
        addedItem.quantity = 1 

        return {
          ...state,
          basket: [...state.basket, action.item],
          // total: state.total + addedItem.price 
        };
      }
      
      case 'EMPTY_BASKET':
        return {
          ...state,
          basket: []
        }
  
      case "REMOVE_FROM_BASKET":
    
        let itemToRemove= state.basket.find(item=> action.id === item.id)
        let new_items = state.basket.filter(item=> action.id !== item.id)
        
        //calculating the total
        let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity )
       
        return{
            ...state,
            basket: new_items,
            total: newTotal
        }
      
      case "ADD_QUANTITY":

       let basket= state.basket.find(item=> item.id === action.id.id)
        // basket.quantity += 1 
        
        return {
          ...state,
          total: state.total + basket.price
        }

        case "SUB_QUANTITY":

          let find = state.basket.find(item=> item.id === action.id)
         
             //if the qt == 0 then it should be removed
        if(find.quantity === 1){
          let new_items = state.basket.filter(item=>item.id !== action.id)
          let newTotal = state.total - find.price
          console.log(new_items)
          return{
              ...state,
              basket: new_items,
              total: newTotal
          }
      }
      else {
        find.quantity -= 1
          let newTotal = state.total - find.price
          return{
              ...state,
              total: newTotal
          }
      }

          case "SET_USER":
            return {
              ...state,
              user: action.user
            }
  
      default:
        return state;
    }
  };
  
  export default reducer;