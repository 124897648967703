import React from "react";
import { Grid } from "@material-ui/core/";
import "./Product.css";
import { Link, useHistory } from "react-router-dom";

export function Product({
  id,
  image,
  title,
  price,
  desc,
  discount,
  subject,
  board,
  course,
}) {
  // let history = useHistory();
  // let url = `/${title}/${board}/${course}/${id}`;
  return (
    <Grid style={{ cursor: "pointer" }} item xs={12} sm={3}>
      <Link
        to={{
          pathname: `/${subject.replace(/\s+/g, "-")}/${board.replace(
            /\s+/g,
            "-"
          )}/${course.replace(/\s+/g, "-")}/${id}`,
          state: { price, image, discount, subject, desc, board, course },
        }}
      >
        <div style={{ width: "100%" }}>
          <img className="product_image" src={image} alt="product" />
        </div>
        <div className="card__title">
          <h3 style={{ fontSize: "18px" }}> {title}</h3>

          <span className="price-item" data-regular-price="">
            {" "}
            Rs {discount}{" "}
          </span>

          <span className="price-item" data-regular-price="">
            <del> Rs {price}</del>
          </span>
        </div>
      </Link>
    </Grid>
  );
}

export function ProductA({
  id,
  image,
  title,
  price,
  desc,
  discount,
  subject,
  board,
  course,
}) {
  let history = useHistory();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        onClick={() =>
          history.push({
            pathname: `/product/${title}/${board}/${course}/${id}`,
          })
        }
        style={{ width: "100%", cursor: "pointer" }}
      >
        <img
          className="product_image"
          style={{ maxWidth: "262px" }}
          src={image}
          alt="product"
        />
      </div>
      <div style={{ maxWidth: "244px" }} className="card__title">
        <h3 style={{ fontSize: "18px" }}> {title}</h3>

        <span className="price-item" data-regular-price="">
          {" "}
          Rs {discount}{" "}
        </span>

        <span className="price-item" data-regular-price="">
          <del> Rs {price}</del>
        </span>
      </div>
    </div>
  );
}
